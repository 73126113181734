import Dropdown from "./dropdown";
import { useState } from "react";

const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);
    return (
        <li className="navBar_title">
            {items.submenu ? (
                <>
                    <button
                        className="navBar_dropdown_title"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{" "}
                    </button>
                    <Dropdown submenus={items.submenu} dropdown={dropdown} />
                </>
            ) : (
                <a className="navBar_link_title" href={items.url}>
                    {items.title}
                </a>
            )}
        </li>
    );
};

export default MenuItems;
