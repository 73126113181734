import React from "react";
import "./DataScreen.css";
import Header from "../Header";
import { useState, useEffect, useCallback } from "react";
import { endpoints, get_request } from "../requests";
import MktDataTable from "../components/mkt_data/MktDataTable";
import { useAuth0 } from "@auth0/auth0-react";

const DataScreen = () => {
    const [mktdata, setmktdata] = useState([]);
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

    const fetch_mktdata_if_authenticated = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const response_data = await get_request(
                endpoints.get_mkt_data_summary,
                token
            );
            if (response_data.is_success) {
                setmktdata(response_data.body);
            }
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        fetch_mktdata_if_authenticated();
    }, [fetch_mktdata_if_authenticated, isLoading]);

    return (
        <div className="dataScreen">
            <Header />
            <div className="dataScreen_content">
                <MktDataTable mkt_data={mktdata} />
            </div>
        </div>
    );
};

export default DataScreen;
