import "./account_data_summary.css";
import RAMTable from "../RAMTable";
import { useState } from "react";
import PositionUpdateModal from "./position_update_modal.js";
import WeightUpdateModal from "./weight_update_modal.js";

const AccountDataSummary = ({ account_data }) => {
    const [position_modal_visible, set_position_modal_visible] =
        useState(false);
    const [update_position, set_update_position] = useState(null);

    const [weight_modal_visible, set_weight_modal_visible] = useState(false);
    const [update_weight, set_update_weight] = useState(null);

    function hide_position_modal() {
        set_update_position(null);
        set_position_modal_visible(false);
    }

    function show_position_modal(item) {
        const position = {
            instrument: item.instrument,
            quantity: item.quantity,
            date: account_data.date,
            account: account_data.account,
        };
        set_update_position(position);
        set_position_modal_visible(true);
    }

    function hide_weight_modal() {
        set_update_weight(null);
        set_weight_modal_visible(false);
    }

    function show_weight_modal(item) {
        const weight = {
            date: account_data.date,
            account: account_data.account,
            asset_name: item["asset_name"],
            prev_weight: item["prev_weight"],
            rebalance_weight: item["rebalance_weight"],
        };
        set_update_weight(weight);
        set_weight_modal_visible(true);
    }

    return (
        <div className="account_data_summary">
            <h3 className="account_data_summary_title">
                Account Summary: {account_data.account}
            </h3>
            <div className="account_data_summary_content">
                <div className="account_data_summary_date">
                    Date: {account_data.date}
                </div>
                <div className="account_data_summary_aum">
                    AUM:{" "}
                    {account_data.aum ? (
                        <>${account_data.aum}</>
                    ) : (
                        <>No AUM found</>
                    )}
                </div>
                <div className="account_data_summary_tables">
                    <div className="account_data_summary_weights">
                        {account_data.weights ? (
                            <RAMTable
                                title={"Weights"}
                                items={account_data.weights}
                                sorterValue={{
                                    column: "asset_name",
                                    state: "asc",
                                }}
                                scopedColumns={{
                                    override: (item) => (
                                        <td>
                                            <button
                                                className="account_data_summary_update_button"
                                                onClick={() =>
                                                    show_weight_modal(item)
                                                }
                                            >
                                                Update
                                            </button>
                                        </td>
                                    ),
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="account_data_summary_position">
                        {account_data.positions ? (
                            <RAMTable
                                title={"Positions"}
                                items={account_data.positions}
                                sorterValue={{
                                    column: "instrument",
                                    state: "asc",
                                }}
                                scopedColumns={{
                                    override: (item) => (
                                        <td>
                                            <button
                                                className="account_data_summary_update_button"
                                                onClick={() =>
                                                    show_position_modal(item)
                                                }
                                            >
                                                Update
                                            </button>
                                        </td>
                                    ),
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <PositionUpdateModal
                    visible={position_modal_visible}
                    call_on_close={hide_position_modal}
                    position={update_position}
                />
                <WeightUpdateModal
                    visible={weight_modal_visible}
                    call_on_close={hide_weight_modal}
                    weight={update_weight}
                />
            </div>
            <hr />
        </div>
    );
};

export default AccountDataSummary;
