import "./root_picker.css";
import Select from "react-select";

const RootMultiPicker = ({ roots, on_change, include_all_option }) => {
    const include_all_roots = include_all_option
        ? [{ label: "All Roots", value: "all" }]
        : [];
    const roots_as_options = roots.map((root) => {
        return { label: root, value: root };
    });
    const option_list = include_all_roots.concat(roots_as_options);

    return (
        <div className="root_multipicker">
            <div className="root_multipicker_title">Choose Root: </div>
            <Select
                options={option_list}
                closeMenuOnSelect={false}
                isMulti
                placeholder={"Select Roots"}
                className="root_multipicker_select"
                onChange={(selectedOptions) => {
                    on_change(selectedOptions);
                }}
            />
        </div>
    );
};

export default RootMultiPicker;
