import RAMTable from "../RAMTable";

const AccountSummary = ({ account_qty_summary }) => {
    return (
        <div className="account_summary_content">
            <RAMTable
                title={"Account Summaries"}
                items={account_qty_summary}
                single_page={true}
                sorterValue={{ column: "account", state: "asc" }}
            />
        </div>
    );
};

export default AccountSummary;
