import { CSmartTable } from "@coreui/react-pro";
import "./SmartTable.css";
import "./RAMTable.css";
import { useState } from "react";

const RAMTable = ({
    title,
    items,
    single_page,
    sorterValue,
    scopedColumns,
    columns,
}) => {
    const [pagination, set_pagination] = useState(!single_page);
    const [search, set_search] = useState(false);

    function to_csv_then_to_clipboard() {
        // Function from: https://stackoverflow.com/questions/63481185/javascript-list-of-dictionariesjson-to-csv
        console.log("Copy CSV");
        const dictionaryKeys = Object.keys(items[0]);
        const dictValuesAsCsv = items.map((dict) =>
            dictionaryKeys
                .map((key) => {
                    if (dict[key] && dict[key].toString().includes(",")) {
                        return `"${dict[key]}"`;
                    }

                    return dict[key];
                })
                .join(",")
        );
        const csv_str = [dictionaryKeys.join(","), ...dictValuesAsCsv].join(
            "\n"
        );
        navigator.clipboard.writeText(csv_str);
    }

    return (
        <>
            <h4 className="ram_table_title">{title}</h4>
            <div className="ram_table_controls">
                <button
                    className="ram_table_controls_copy_csv ram_button"
                    onClick={to_csv_then_to_clipboard}
                >
                    Copy CSV
                </button>
                <div
                    className="ram_table_controls_pagination ram_table_controls_input"
                    onClick={() => {
                        set_pagination(!pagination);
                    }}
                >
                    <input
                        className="ram_table_controls_checkbox ram_table_controls_input"
                        type="checkbox"
                        name="pagination"
                        value="pagination"
                        checked={pagination}
                        readOnly
                    />
                    <label> Pages</label>
                    <br />
                </div>
                <div
                    className="ram_table_controls_search ram_table_controls_input"
                    onClick={() => {
                        set_search(!search);
                    }}
                >
                    <input
                        className="ram_table_controls_checkbox"
                        type="checkbox"
                        name="search"
                        value="search"
                        checked={search}
                        readOnly
                    />
                    <label> Searchable</label>
                    <br />
                </div>
            </div>
            <div className="ram_table_table">
                <CSmartTable
                    items={items}
                    tableProps={{
                        striped: true,
                    }}
                    cleaner={search}
                    tableFilter={search}
                    columnSorter={search}
                    pagination={pagination}
                    itemsPerPage={pagination ? 20 : 99999}
                    sorterValue={sorterValue}
                    scopedColumns={scopedColumns}
                    columns={columns}
                />
            </div>
        </>
    );
};

export default RAMTable;
