import React from "react";
import "./DataOverrideScreen.css";
import Header from "../Header";
import AUMOverride from "../components/data_override/aum_override";
import TradeDataGenerate from "../components/data_override/trade_data_override";
import { useState, useEffect, useCallback } from "react";
import { endpoints, get_request } from "../requests";
import { useAuth0 } from "@auth0/auth0-react";
import BarDataOverride from "../components/data_override/bar_data_override";
import PositionDataOverride from "../components/data_override/position_data_override";
import WeightDataOverride from "../components/data_override/weight_data_override";
import HolidayOverride from "../components/data_override/holiday_override";

const DataOverrideScreen = () => {
    const [all_accounts, set_all_accounts] = useState([]);
    const [all_roots, set_all_roots] = useState([]);
    const [all_exchanges, set_all_exchanges] = useState([]);
    const { getAccessTokenSilently, isLoading } = useAuth0();

    const fetch_if_authenticated = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const account_data_response = await get_request(
            endpoints.get_accounts,
            token
        );
        const root_data_response = await get_request(
            endpoints.get_roots,
            token
        );
        const holiday_response = await get_request(endpoints.get_exchanges, token);
        if (account_data_response.is_success) {
            set_all_accounts(account_data_response.body);
        }
        if (root_data_response.is_success) {
            set_all_roots(root_data_response.body);
        }
        if (holiday_response.is_success) {
            set_all_exchanges(holiday_response.body);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetch_if_authenticated();
    }, [fetch_if_authenticated, isLoading]);

    return (
        <div className="data_override">
            <Header />
            <div className="data_override_content">
                <h2 className="data_override_title">Data Override Dashboard</h2>
                {all_accounts.length > 0 ? (
                    <>
                        <div className="data_override_trade_data">
                            <TradeDataGenerate
                                accounts={all_accounts}
                                roots={all_roots}
                            />
                            <hr />
                        </div>
                        <div className="data_override_aum">
                            <AUMOverride accounts={all_accounts} />
                            <hr />
                        </div>
                        <div className="data_override_position">
                            <h4 className="data_override_title">
                                Position Override
                            </h4>
                            <PositionDataOverride
                                position={{}}
                                allow_base_updates={true}
                                all_accounts={all_accounts}
                            />
                        </div>
                        <hr />
                        <div className="data_override_weights">
                            <h4 className="data_override_title">
                                Weight Override
                            </h4>
                            <WeightDataOverride
                                weight={{}}
                                allow_base_updates={true}
                                all_accounts={all_accounts}
                            />
                        </div>
                        <hr />
                    </>
                ) : (
                    <></>
                )}
                <div className="data_override_bars">
                    <h4 className="data_override_title">Instrument Override</h4>
                    <BarDataOverride bar={{}} allow_base_updates={true} />
                </div>
                <hr />
                <div className="data_override_holiday">
                    <h4 className="data_override_title">
                        Holiday Override
                    </h4>
                    <HolidayOverride
                        all_roots={all_roots}
                        all_exchanges={all_exchanges}
                    />
                </div>

                <hr />
            </div>
        </div>
    );
};

export default DataOverrideScreen;
