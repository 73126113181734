import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_request } from "../../requests";
import "./bar_data_override.css";

const BlockType = {
    BlockIfShould: "BlockIfShould",
    Allow: "Allow",
};

const ConfirmText = {
    OverrideBar: "Override Bar",
    DoubleCheck: "Confirm Double Check",
    Success: "Success!",
};

const BarDataOverride = ({ bar, allow_base_updates }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [is_confirm_btn_enabled, set_is_confirm_btn_enabled] = useState(true);
    const [confirm_btn_txt, set_confirm_btn_txt] = useState(
        ConfirmText.OverrideBar
    );

    const [asset_name, set_asset_name] = useState(bar.asset_name || "");
    const [instrument, set_instrument] = useState(bar.instrument || "");
    const [config_date, set_config_date] = useState(
        bar.config_date || new Date().toISOString().split("T")[0]
    );
    const [session_start] = useState(
        bar["session.start"] ||
            new Date().toISOString().split("T")[0] + "T00:00:00.000Z"
    );
    const [session_end] = useState(
        bar["session.end"] ||
            new Date().toISOString().split("T")[0] + "T23:59:59.000Z"
    );
    const [open, set_open] = useState(bar["bar.open"] || "");
    const [high, set_high] = useState(bar["bar.high"] || "");
    const [low, set_low] = useState(bar["bar.low"] || "");
    const [close, set_close] = useState(bar["bar.close"] || "");
    const [volume, set_volume] = useState(bar["bar.volume"]);
    const [init_close] = useState(!allow_base_updates ? parseFloat(close) : "");
    const [block_type, set_block_type] = useState(
        !allow_base_updates ? BlockType.BlockIfShould : BlockType.Allow
    );

    const on_bar_confirmed = useCallback(async () => {
        set_is_confirm_btn_enabled(false);

        if (
            asset_name === "" ||
            instrument === "" ||
            open === "" ||
            high === "" ||
            low === "" ||
            close === ""
        ) {
            set_response_msg({
                text: "Please fill in all fields.",
                color: "red",
            });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideBar);
            return;
        }

        const new_close = parseFloat(close);
        if (new_close === init_close) {
            set_response_msg({ text: "No change detected.", color: "red" });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideBar);
            return;
        }

        if (block_type === BlockType.BlockIfShould) {
            const percent_change = Math.abs(
                ((new_close - init_close) / init_close) * 100
            );
            if (percent_change > 10) {
                set_response_msg({
                    text: "Please double check close...",
                    color: "black",
                });
                set_block_type(BlockType.Allow);
                set_confirm_btn_txt(ConfirmText.DoubleCheck);
                set_is_confirm_btn_enabled(true);
                return;
            }
        }

        set_response_msg({ text: "Sending bar data...", color: "black" });
        set_block_type(BlockType.BlockIfShould);

        const body = {
            asset_name,
            instrument,
            config_date,
            session_start,
            session_end,
            open,
            high,
            low,
            close,
            volume: volume ? parseInt(volume) : null,
        };
        const token = await getAccessTokenSilently();
        const response_data = await post_request(
            endpoints.post_bar_override,
            token,
            body
        );
        if (response_data.is_success) {
            set_response_msg({ text: "Success!", color: "green" });
            set_is_confirm_btn_enabled(false);
            set_confirm_btn_txt(ConfirmText.Success);
        } else {
            set_response_msg({ text: response_data.error, color: "red" });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideBar);
        }
    }, [
        asset_name,
        block_type,
        close,
        config_date,
        getAccessTokenSilently,
        high,
        init_close,
        instrument,
        low,
        open,
        session_end,
        session_start,
        volume,
    ]);

    return (
        <div className="bar_data_override">
            <div className="bar_data_override_values">
                <div className="bar_data_override_node">
                    <label>Asset Name: </label>
                    <input
                        className="bar_data_override_asset"
                        type="other"
                        disabled={!allow_base_updates}
                        value={asset_name}
                        onChange={(event) => set_asset_name(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Instrument: </label>
                    <input
                        className="bar_data_override_instrument"
                        type="other"
                        disabled={!allow_base_updates}
                        value={instrument}
                        onChange={(event) => set_instrument(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Date: </label>
                    <input
                        className="bar_data_override_config_date"
                        type="date"
                        disabled={!allow_base_updates}
                        value={config_date}
                        onChange={(event) =>
                            set_config_date(event.target.value)
                        }
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Open: </label>
                    <input
                        className="bar_data_override_open"
                        type="number"
                        disabled={!allow_base_updates}
                        value={open}
                        onChange={(event) => set_open(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>High: </label>
                    <input
                        className="bar_data_override_high"
                        type="number"
                        disabled={!allow_base_updates}
                        value={high}
                        onChange={(event) => set_high(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Low: </label>
                    <input
                        className="bar_data_override_low"
                        type="number"
                        disabled={!allow_base_updates}
                        value={low}
                        onChange={(event) => set_low(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Close: </label>
                    <input
                        className="bar_data_override_close"
                        type="number"
                        value={close}
                        onChange={(event) => set_close(event.target.value)}
                    />
                </div>
                <div className="bar_data_override_node">
                    <label>Volume: </label>
                    <input
                        className="bar_data_override_volume"
                        type="number"
                        disabled={!allow_base_updates}
                        value={volume}
                        onChange={(event) => set_volume(event.target.value)}
                    />
                </div>
            </div>
            <div className="bar_data_override_footer">
                <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                <button
                    className="bar_update_submit_btn ram_button"
                    disabled={!is_confirm_btn_enabled}
                    onClick={on_bar_confirmed}
                >
                    {confirm_btn_txt}
                </button>
            </div>
        </div>
    );
};

export default BarDataOverride;
