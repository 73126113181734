import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_request } from "../../requests";
import "./weight_data_override.css";
import AccountPicker from "../account_picker";

const BlockType = {
    BlockIfShould: "BlockIfShould",
    Allow: "Allow",
};

const ConfirmText = {
    OverrideWeight: "Override Weight",
    DoubleCheck: "Confirm Double Check",
    Success: "Success!",
};

const WeightDataOverride = ({ weight, allow_base_updates, all_accounts }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [is_confirm_btn_enabled, set_is_confirm_btn_enabled] = useState(true);
    const [confirm_btn_txt, set_confirm_btn_txt] = useState(
        ConfirmText.OverrideWeight
    );

    const [account, set_account] = useState(weight.account || "");
    const [asset_name, set_asset_name] = useState(weight.asset_name || "");
    const [prev_weight, set_prev_weight] = useState(weight.prev_weight || "");
    const [rebalance_weight, set_rebalance_weight] = useState(
        weight.rebalance_weight || ""
    );
    const [init_rebalance_weight] = useState(
        !allow_base_updates ? parseFloat(weight.rebalance_weight) : ""
    );
    const [block_type, set_block_type] = useState(
        !allow_base_updates ? BlockType.BlockIfShould : BlockType.Allow
    );
    const [date, set_date] = useState(
        weight.date || new Date().toISOString().split("T")[0]
    );

    const on_weight_confirmed = useCallback(async () => {
        set_is_confirm_btn_enabled(false);
        if (
            account === "" ||
            asset_name === "" ||
            prev_weight === "" ||
            rebalance_weight === ""
        ) {
            set_response_msg({
                text: "Please fill in all fields.",
                color: "red",
            });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideWeight);
            return;
        }

        const new_rebalance_weight = parseFloat(rebalance_weight);
        if (new_rebalance_weight === init_rebalance_weight) {
            set_response_msg({ text: "No change detected.", color: "red" });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideWeight);
            return;
        }

        if (block_type === BlockType.BlockIfShould) {
            const percent_change = Math.abs(
                ((new_rebalance_weight - init_rebalance_weight) /
                    init_rebalance_weight) *
                    100
            );
            if (percent_change > 10) {
                set_response_msg({
                    text: "Please double check weight...",
                    color: "black",
                });
                set_block_type(BlockType.Allow);
                set_confirm_btn_txt(ConfirmText.DoubleCheck);
                set_is_confirm_btn_enabled(true);
                return;
            }
        }

        set_response_msg({ text: "Sending update...", color: "black" });
        set_block_type(BlockType.BlockIfShould);

        const body = {
            date,
            account,
            asset_name,
            prev_weight,
            rebalance_weight,
        };
        const token = await getAccessTokenSilently();
        const response_data = await post_request(
            endpoints.post_weight_override,
            token,
            body
        );
        if (response_data.is_success) {
            set_response_msg({ text: "Success!", color: "green" });
            set_is_confirm_btn_enabled(false);
            set_confirm_btn_txt(ConfirmText.Success);
        } else {
            set_response_msg({ text: response_data.error, color: "red" });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideWeight);
        }
    }, [
        account,
        asset_name,
        prev_weight,
        rebalance_weight,
        date,
        getAccessTokenSilently,
        init_rebalance_weight,
        block_type,
    ]);

    return (
        <div className="weight_data_override">
            <div className="weight_data_override_values">
                <div className="weight_data_override_node">
                    <label>Date: </label>
                    <input
                        className="weight_data_override_date"
                        type="date"
                        disabled={!allow_base_updates}
                        value={date}
                        onChange={(event) => set_date(event.target.value)}
                    />
                </div>
                <div className="weight_data_override_node">
                    <div className="weight_account_picker">
                        <AccountPicker
                            accounts={all_accounts}
                            on_change={(event) =>
                                set_account(event.target.value)
                            }
                            include_all_option={false}
                            default_account={account}
                            disabled={!allow_base_updates}
                        />
                    </div>
                </div>
                <div className="weight_data_override_node">
                    <label>Asset Name: </label>
                    <input
                        className="weight_data_override_asset_name"
                        type="text"
                        disabled={!allow_base_updates}
                        value={asset_name}
                        onChange={(event) => set_asset_name(event.target.value)}
                    />
                </div>
                <div className="weight_data_override_node">
                    <label>Previous Weight: </label>
                    <input
                        className="weight_data_override_prev"
                        type="number"
                        disabled={!allow_base_updates}
                        value={prev_weight}
                        onChange={(event) =>
                            set_prev_weight(event.target.value)
                        }
                    />
                </div>
                <div className="weight_data_override_node">
                    <label>Rebalance Weight: </label>
                    <input
                        className="weight_data_override_rebal"
                        type="number"
                        value={rebalance_weight}
                        onChange={(event) =>
                            set_rebalance_weight(event.target.value)
                        }
                    />
                </div>
            </div>
            <div className="weight_data_override_footer">
                <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                <button
                    className="bar_update_submit_btn ram_button"
                    disabled={!is_confirm_btn_enabled}
                    onClick={on_weight_confirmed}
                >
                    {confirm_btn_txt}
                </button>
            </div>
        </div>
    );
};

export default WeightDataOverride;
