import OrderUpdateTable from "./OrderUpdateTable";
import "../SmartTable.css";
import "../order_state/OrderExpand.css";

const OrderExpand = ({ order }) => {
    return (
        <div className="order_expand">
            <h3>{order.instrument} Details</h3>
            <div className="order_expand_update_table">
                <OrderUpdateTable updates={order.updates} />
            </div>
        </div>
    );
};

export default OrderExpand;
