import "./account_sub_report.css";
import ReviewState, { ReviewOption, ReviewScope } from "./review_state";
import RAMTable from "../RAMTable";

const AccountSubReport = ({
    order_sub_report,
    report_id,
    account_qty_summary,
    show_review_modal_fn,
}) => {
    const show_order_modal = (item, review_option) => {
        const review_state = new ReviewState(
            ReviewScope.ORDER,
            review_option,
            item,
            report_id,
            order_sub_report.sub_report_id,
            item.order_id
        );
        show_review_modal_fn(review_state);
    };

    const show_sub_report_modal = (item, review_option) => {
        const review_state = new ReviewState(
            ReviewScope.SUB_REPORT,
            review_option,
            item,
            report_id,
            order_sub_report.sub_report_id,
            null
        );
        show_review_modal_fn(review_state);
    };

    return (
        <div className="account_sub_report">
            <div className="account_sub_report_order_table">
                <RAMTable
                    title={"Account: " + order_sub_report.account}
                    items={populated_items(order_sub_report)}
                    single_page={false}
                    sorterValue={{ column: "amount", state: "desc" }}
                    scopedColumns={{
                        order_id: (item) => (
                            <td>{item.order_id.slice(0, 8)}</td>
                        ),
                        review: (item) =>
                            item.approval_status === "None" ? (
                                <td>
                                    <button
                                        className="ord_review_approve_btn_sm"
                                        onClick={() =>
                                            show_order_modal(
                                                item,
                                                ReviewOption.APPROVE
                                            )
                                        }
                                    >
                                        A
                                    </button>
                                    <button
                                        className="ord_review_reject_btn_sm"
                                        onClick={() =>
                                            show_order_modal(
                                                item,
                                                ReviewOption.REJECT
                                            )
                                        }
                                    >
                                        R
                                    </button>
                                </td>
                            ) : (
                                <td></td>
                            ),
                    }}
                />
            </div>

            <div className="account_sub_report_summary">
                <div className="account_sub_report_summary_title">
                    Account Summary
                </div>
                <div className="account_sub_report_details">
                    <div className="account_sub_report_account">
                        Account: {order_sub_report.account}
                    </div>

                    <div className="account_sub_report_total_qty">
                        Total Amount:{" "}
                        {account_qty_summary
                            ? account_qty_summary.total_qty
                            : ""}
                    </div>
                    <div className="account_sub_report_total_orders">
                        Total Order Count:{" "}
                        {account_qty_summary
                            ? account_qty_summary.total_orders
                            : ""}
                    </div>
                </div>
                <div className="account_sub_report_buttons">
                    <button
                        className="ord_review_approve_btn ram_button"
                        onClick={() =>
                            show_sub_report_modal(
                                account_qty_summary,
                                ReviewOption.APPROVE
                            )
                        }
                    >
                        Approve Account
                    </button>
                    <button
                        className="ord_review_reject_btn"
                        onClick={() =>
                            show_sub_report_modal(
                                account_qty_summary,
                                ReviewOption.REJECT
                            )
                        }
                    >
                        Reject Account
                    </button>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default AccountSubReport;

function populated_items(order_sub_report) {
    if (order_sub_report.order_summaries) {
        for (let i = 0; i < order_sub_report.order_summaries.length; i++) {
            delete order_sub_report.order_summaries[i]["account"];
            order_sub_report.order_summaries[i]["review"] =
                "review_button_column"; // This value is overwritten in scopedColumns
        }
        return order_sub_report.order_summaries;
    } else {
        return [];
    }
}
