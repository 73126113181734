import { ReviewScope } from "./review_state";

const ReviewModalContent = ({ review_state }) => {
    function order_content(item) {
        return (
            item && (
                <div>
                    <h4>Order</h4>
                    <div>Id: {item.order_id}</div>
                    <div>Broker: {item.broker}</div>
                    <div>Account: {item.account}</div>
                    <div>Instrument: {item.instrument}</div>
                    <div>Side: {item.side}</div>
                    <div>Amount: {item.amount}</div>
                </div>
            )
        );
    }
    function sub_report_content(item) {
        return (
            item && (
                <div>
                    <h4>Account</h4>
                    <div>Account: {item.account}</div>
                    <div>Fund: {item.fund}</div>
                    <div>Broker: {item.broker}</div>
                    <div>Order Count: {item.total_orders}</div>
                    <div>Total Amount: {item.total_qty}</div>
                </div>
            )
        );
    }
    function report_content(item) {
        return (
            item && (
                <div>
                    <h4>Report</h4>
                    <div>Report Date: {item.report_date}</div>
                    <div>transaction_date: {item.transaction_date}</div>
                </div>
            )
        );
    }
    if (review_state.review_scope === ReviewScope.ORDER) {
        return order_content(review_state.item);
    } else if (review_state.review_scope === ReviewScope.SUB_REPORT) {
        return sub_report_content(review_state.item);
    } else if (review_state.review_scope === ReviewScope.REPORT) {
        return report_content(review_state.item);
    } else {
        return <div></div>;
    }
};

export default ReviewModalContent;
