import "./trade_data_summary.css";
import RAMTable from "../RAMTable";

const TradeDataSummary = ({ trade_data }) => {
    function prev_day_data() {
        let instrument_data = [];
        for (
            let i = 0;
            i < trade_data.account_data.asset_trade_data.length;
            i++
        ) {
            let asset_trade_data = trade_data.account_data.asset_trade_data[i];
            for (
                let i = 0;
                i < asset_trade_data.instrument_trade_data.length;
                i++
            ) {
                if (
                    trade_data.date ===
                    asset_trade_data.instrument_trade_data[i].price_date
                ) {
                    continue;
                }
                delete asset_trade_data.instrument_trade_data[i].is_rolling;
                delete asset_trade_data.instrument_trade_data[i]
                    .trades_during_session;
                delete asset_trade_data.instrument_trade_data[i]
                    .trades_next_session;
                instrument_data.push(asset_trade_data.instrument_trade_data[i]);
            }
        }
        return instrument_data;
    }

    function instrument_data() {
        let instrument_data = [];
        for (
            let i = 0;
            i < trade_data.account_data.asset_trade_data.length;
            i++
        ) {
            let asset_trade_data = trade_data.account_data.asset_trade_data[i];
            for (
                let i = 0;
                i < asset_trade_data.instrument_trade_data.length;
                i++
            ) {
                // add asset_name to instrument_trade_data as first key of hashmap
                var data = { asset_name: asset_trade_data.asset_name };
                var ins_data = Object.assign(
                    {},
                    data,
                    asset_trade_data.instrument_trade_data[i]
                );
                delete ins_data.is_rolling;
                delete ins_data.trades_during_session;
                delete ins_data.trades_next_session;
                instrument_data.push(ins_data);
            }
        }
        return instrument_data;
    }

    function asset_data() {
        let asset_data_lst = [];
        for (
            let i = 0;
            i < trade_data.account_data.asset_trade_data.length;
            i++
        ) {
            let asset_data = JSON.parse(
                JSON.stringify(trade_data.account_data.asset_trade_data[i])
            ); // deep copy https://stackoverflow.com/questions/39968366/how-to-deep-copy-a-custom-object-in-javascript
            delete asset_data.instrument_trade_data;
            asset_data_lst.push(asset_data);
        }
        return asset_data_lst;
    }

    return (
        <div className="trade_data_summary">
            <h3 className="trade_data_summary_title">
                Consolidated Trade Data Summary:{" "}
                {trade_data.account_data.account}
            </h3>
            <div className="trade_data_summary_content">
                <div className="trade_data_summary_id">
                    Consolidation ID: {trade_data.id}{" "}
                </div>
                <div className="trade_data_summary_date">
                    Config Date: {trade_data.date}{" "}
                </div>
                <div className="trade_data_summary_at">
                    Consolidated At:{" "}
                    {new Date(trade_data.consolidated_at).toLocaleString()}
                </div>
                <div className="trade_data_summary_aum">
                    AUM: {trade_data.account_data.aum}
                </div>

                <div className="trade_data_summary_asset_values">
                    <h4 className="trade_data_summary_asset_values_title">
                        Asset Values
                    </h4>
                    <RAMTable
                        items={asset_data()}
                        sorterValue={{ column: "asset_name", state: "asc" }}
                    />
                </div>
                <div className="trade_data_summary_tables">
                    <div className="trade_data_summary_instrument_values">
                        <h4 className="trade_data_summary_instrument_title">
                            Instrument Values
                        </h4>
                        <RAMTable
                            items={instrument_data()}
                            sorterValue={{ column: "asset_name", state: "asc" }}
                        />
                    </div>
                    <div className="trade_data_summary_position">
                        <h4 className="trade_data_summary_position_title">
                            Positions
                        </h4>
                        <RAMTable
                            items={trade_data.account_data.positions}
                            sorterValue={{ column: "instrument", state: "asc" }}
                        />
                    </div>
                </div>
                <div className="trade_data_summary_prev_instrument_values">
                    {prev_day_data().length > 0 ? (
                        <>
                            <h4 className="trade_data_summary_instrument_title">
                                Previous Day Instrument Warning
                            </h4>
                            <p className="trade_data_summary_prev_instrument_warning">
                                These Instruments are using data from a day that
                                is not the same as the current config day.
                            </p>

                            <RAMTable
                                items={prev_day_data()}
                                sorterValue={{
                                    column: "asset_name",
                                    state: "asc",
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <hr />
        </div>
    );
};

export default TradeDataSummary;
