import "./exchange_picker.css"

const ExchangePicker = ({ exchanges, on_change }) => {
    return (
        <div className="exchange-picker">
            <div className="exchange-picker-title">Choose Exchange: </div>
            <select onChange={on_change} className="exchange-picker-select">
                <option key={-1} value="">
                    Choose Exchange
                </option>
                {exchanges.map((exchange, index) => (
                    <option key={index} value={exchange}>
                        {exchange}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default ExchangePicker;