import RAMTable from "../RAMTable";

const SanityCheckSummary = ({ sanity_checks }) => {
    return (
        <div className="sanity_checks_content">
            <RAMTable
                title={"Sanity Checks"}
                items={sanity_checks}
                sorterValue={{ column: "severity", state: "asc" }}
            />
        </div>
    );
};

export default SanityCheckSummary;
