const DatePicker = ({ date, on_change }) => {
    return (
        <div className="date_picker">
            <h3>Choose Date</h3>
            <input
                className="date_picker_input"
                type="date"
                value={date}
                onChange={on_change}
            />
        </div>
    );
};

export default DatePicker;
