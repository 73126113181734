import "./root_picker.css";

const RootPicker = ({ roots, on_change, include_all_option }) => {
    return (
        <div className="root_picker">
            <div className="root_picker_title">Choose Root: </div>
            <select onChange={on_change} className="root_picker_select">
                <option key={-2} value="">
                    Choose Root
                </option>
                {include_all_option ? (
                    <option key={-1} value="all">
                        All Roots
                    </option>
                ) : (
                    <></>
                )}
                {roots.map((root, index) => (
                    <option key={index} value={root}>
                        {root}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default RootPicker;
