import { CModal, CModalHeader, CModalBody, CModalFooter } from "@coreui/react";
import "../SmartModal.css";
import WeightDataOverride from "../data_override/weight_data_override";

const WeightUpdateModal = ({ visible, call_on_close, weight }) => {
    return (
        <>
            {visible && (
                <div>
                    <CModal visible={visible} onClose={() => call_on_close()}>
                        <CModalHeader onClose={() => call_on_close()}>
                            <p>Weight Price Override</p>
                        </CModalHeader>
                        <CModalBody>
                            <WeightDataOverride
                                weight={weight}
                                all_accounts={[weight.account]}
                            />
                        </CModalBody>
                        <CModalFooter>
                            <button
                                className="ram_button"
                                onClick={() => call_on_close()}
                            >
                                Close
                            </button>
                        </CModalFooter>
                    </CModal>
                </div>
            )}
        </>
    );
};

export default WeightUpdateModal;
