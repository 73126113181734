import React from "react";
import "./OrdersScreen.css";
import Header from "../Header";
import { useState, useEffect, useCallback } from "react";
import { endpoints, post_request } from "../requests";
import OrdersTable from "../components/order_state/OrdersTable";
import { useAuth0 } from "@auth0/auth0-react";

const OrdersScreen = () => {
    const [api_call_on_load, set_api_call_on_load] = useState(true);
    const [orders, set_orders] = useState({ order_summary: [] });
    const [created_on_or_after, set_created_on_or_after] = useState(
        created_on_or_after_default()
    );
    const [created_on_or_before, set_created_on_or_before] = useState("");
    const [active_only, set_active_only] = useState(true);
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    function created_on_or_after_default() {
        let req_date = new Date();
        if (req_date.getDay() === 0) {
            // Sunday
            req_date.setDate(req_date.getDate() - 2); // Friday
        } else if (req_date.getDay() === 1) {
            // Monday
            req_date.setDate(req_date.getDate() - 3); // Friday
        } else {
            req_date.setDate(req_date.getDate() - 1); // Yesterday
        }
        return req_date.toLocaleDateString("en-CA");
    }

    function update_created_on_or_after(event) {
        event.preventDefault();
        console.log("Date Updated " + event.target.value);
        set_created_on_or_after(event.target.value);
    }

    function update_created_on_or_before(event) {
        event.preventDefault();
        console.log("Date Updated " + event.target.value);
        set_created_on_or_before(event.target.value);
    }

    const fetch_order_data_if_authenticated = useCallback(async () => {
        set_response_msg({ text: "", color: "white" });
        if (isAuthenticated) {
            set_response_msg({ text: "Sending request...", color: "black" });
            const token = await getAccessTokenSilently();
            let body = {
                created_on_or_after: created_on_or_after,
                created_on_or_before:
                    created_on_or_before === "" ? null : created_on_or_before,
                active_only: active_only,
            };
            const response_data = await post_request(
                endpoints.post_oms_orders,
                token,
                body
            );
            if (response_data.is_success) {
                set_orders(response_data.body);
                set_response_msg({ text: "", color: "white" });
            } else {
                set_response_msg({
                    text: response_data.error_message,
                    color: "red",
                });
                console.error(
                    "Error fetching orders: " + response_data.error_message
                );
            }
        }
    }, [
        active_only,
        created_on_or_after,
        created_on_or_before,
        getAccessTokenSilently,
        isAuthenticated,
    ]);

    useEffect(() => {
        if (api_call_on_load) {
            fetch_order_data_if_authenticated();
            set_api_call_on_load(false);
        }
    }, [fetch_order_data_if_authenticated, api_call_on_load]);

    return (
        <div className="ordersScreen">
            <Header />
            <div className="ordersScreen_content">
                <div className="ordersScreen_title">Orders</div>
                <div className="ordersScreen_input">
                    <div className="ord_review_date_form">
                        <label> From: </label>
                        <input
                            className="date_picker_input"
                            type="date"
                            value={created_on_or_after}
                            onChange={update_created_on_or_after}
                        />
                        <label> To: </label>
                        <input
                            className="date_picker_input"
                            type="date"
                            value={created_on_or_before}
                            onChange={update_created_on_or_before}
                        />
                        <div
                            className="ram_table_controls_input"
                            onClick={() => {
                                set_active_only(!active_only);
                            }}
                        >
                            <input
                                className="ram_table_controls_checkbox"
                                type="checkbox"
                                name="active_only"
                                value="active_only"
                                checked={active_only}
                                readOnly
                            />
                            <label> Active Only </label>
                        </div>
                        <input
                            type="button"
                            value="Query Orders"
                            className="order_history_btn ram_button"
                            onClick={fetch_order_data_if_authenticated}
                        />
                        <p style={{ color: response_msg.color }}>
                            {response_msg.text}
                        </p>
                    </div>
                </div>
                {orders && <OrdersTable orders={orders} />}
            </div>
        </div>
    );
};

export default OrdersScreen;
