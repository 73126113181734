import React from "react";
import "./OrderReviewScreen.css";
import Header from "../Header";
import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, get_request } from "../requests";
import OrdersReview from "../components/order_review/order_review";
import DatePicker from "../components/date_picker";

const OrderReviewScreen = () => {
    const [report_date, set_report_date] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [error_msg, set_error_msg] = useState("");
    const [order_report, set_order_report] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const format_and_set_order_report = useCallback((body) => {
        if (body == null) {
            set_order_report(body);
            return;
        }
        for (const order_sub_report of body.order_sub_reports) {
            for (const order_summary of order_sub_report.order_summaries) {
                order_summary["curr_weight"] = weight_to_pct(
                    order_summary["curr_weight"]
                );
                order_summary["target_weight"] = weight_to_pct(
                    order_summary["target_weight"]
                );
            }
        }
        set_order_report(body);
    }, []);

    const fetch_report_for_date = useCallback(async () => {
        set_error_msg("");
        set_order_report(null);
        console.log(report_date);
        const token = await getAccessTokenSilently();
        const date_endpoint = endpoints.get_report_by_date + report_date;
        const response_data = await get_request(date_endpoint, token);
        if (response_data.is_success) {
            format_and_set_order_report(response_data.body);
        } else {
            set_error_msg(response_data.error);
        }
    }, [getAccessTokenSilently, report_date, format_and_set_order_report]);

    async function refresh_report_silently_with_id() {
        if (order_report) {
            const id_endpoint =
                endpoints.get_report_by_id + order_report.report_id;
            const token = await getAccessTokenSilently();
            const response_data = await get_request(id_endpoint, token);
            if (response_data.is_success) {
                format_and_set_order_report(response_data.body);
            }
        }
    }

    function weight_to_pct(weight) {
        if (weight === null || weight === undefined) {
            return "";
        }
        const float_weight = parseFloat(weight);
        return (float_weight * 100).toFixed(2) + "%";
    }

    function update_date(event) {
        console.log("Date Updated " + event.target.value);
        set_report_date(event.target.value);
    }

    return (
        <div className="ord_review">
            <Header />
            <div className="ord_review_content">
                <h2 className="ord_review_title">Order Review</h2>
                <div className="ord_review_date_form">
                    <DatePicker date={report_date} on_change={update_date} />
                    <input
                        type="button"
                        value="Generate Report"
                        className="ord_review_btn ram_button"
                        onClick={fetch_report_for_date}
                    />
                </div>
                <div className="ord_review_error_msg">{error_msg}</div>
                <div className="ord_review_report">
                    <h3>Order Review Report</h3>
                    {order_report && (
                        <OrdersReview
                            order_report={order_report}
                            report_refresh_fn={refresh_report_silently_with_id}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderReviewScreen;
