export const orders_columns = [
    { key: "instrument" },
    { key: "state" },
    { key: "account" },
    { key: "effective_time" },
    { key: "broker" },
    { key: "side" },
    { key: "order_type" },
    { key: "rem_qty", label: "(Filled/Qty)" },
    { key: "avg_price" },
    { key: "bidask", label: "(Bid/Ask)" },
    {
        key: "show_details",
        label: "",
        sorter: false,
        _props: { color: "primary", className: "fw-semibold" },
    },
];

export const mkt_data_columns = [
    { key: "instrument" },
    { key: "currency" },
    { key: "last_trade_prc", label: "Trade Prc" },
    { key: "trade_prc_date", label: "Trade Prc Dt" },
    { key: "high" },
    { key: "low" },
    { key: "open" },
    { key: "bidask", label: "Bid/Ask" },
    { key: "quote_datetime", label: "Quote Time" },
    { key: "volume", label: "Volume" },
    { key: "close_bidask", label: "Last Close Bid/Ask" },
    { key: "close_quote_date", label: "Last Close Date" },
    { key: "settle_prc" },
    { key: "settle_date" },
    { key: "open_interest" },
    { key: "open_interest_date" },
    { key: "create_time", label: "System Time" },
];
