import RAMTable from "../RAMTable";

const MandateVolumeSummary = ({ mandate_volume_summary }) => {
    const formatted_items = () => {
        for (let i in mandate_volume_summary) {
            mandate_volume_summary[i].volume_avg = parseFloat(
                mandate_volume_summary[i].volume_avg
            );
            mandate_volume_summary[i].volume_pct = parseFloat(
                (
                    (Math.abs(mandate_volume_summary[i].target_position) /
                        mandate_volume_summary[i].volume_avg) *
                    100
                ).toFixed(2)
            );
        }
        return mandate_volume_summary;
    };
    return (
        <div className="mandate_volume_summary_content">
            <RAMTable
                title={"Mandate Volume Report"}
                items={formatted_items()}
                sorterValue={{ column: "volume_pct", state: "desc" }}
                scopedColumns={{
                    volume_pct: (item) => <td>{item.volume_pct}%</td>,
                }}
            />
        </div>
    );
};

export default MandateVolumeSummary;
