import "./Nav.css";
import MenuItems from "./components/nav/menu_item";

const menu_items = [
    {
        title: "Data Management",
        url: "",
        submenu: [
            {
                title: "Account Data",
                url: "/data/account_data",
            },
            {
                title: "Bar Data",
                url: "/data/bar_data",
            },
            {
                title: "Mkt Data",
                url: "/data/mkt_data_summary",
            },
            {
                title: "Trade Data",
                url: "/data/consolidated_trade_data",
            },
            {
                title: "Data Override",
                url: "/data/data_override",
            },
        ],
    },
    {
        title: "Order Management",
        url: "",
        submenu: [
            {
                title: "Reconciliation",
                url: "/order/oms_orders",
            },
            {
                title: "Order Review",
                url: "/order/review",
            },
        ],
    },
];

const NavBar = () => {
    return (
        <nav className="navBar">
            <div className="navBar_contents">
                <ul className="navBar_menus">
                    {menu_items.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                    })}
                </ul>
            </div>
        </nav>
    );
};

export default NavBar;
