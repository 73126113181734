import { useState, useCallback } from "react";
import ExchangePicker from "./exchange_picker";
import CalendarPicker from "./calendar_picker";
import { endpoints, get_request } from "../requests";
import { useAuth0 } from "@auth0/auth0-react";
import "./exchange_calendar_picker.css";

const ExchangeCalendarPicker = ({ all_exchanges, on_exchange_change, on_calendar_change }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [exchange, set_exchange] = useState("");
    const [exchange_calendars, set_exchange_calendars] = useState([]);
    const [calendar, set_calendar] = useState({});
    const [response_msg, set_response_msg] = useState({ text: "", color: "white" });

    const update_trading_calendar = useCallback(async (new_calendar) => {
        set_calendar(new_calendar);
        on_calendar_change(new_calendar);
        return calendar;
    }, [on_calendar_change, calendar])

    const update_active_exchange = useCallback(async (new_exchange) => {
        set_response_msg({ text: "", color: "white" });
        set_exchange(new_exchange);
        on_exchange_change(new_exchange);
        update_trading_calendar("");
        if (new_exchange === "") {
            return;
        }
        const token = await getAccessTokenSilently();
        const endpoint = endpoints.get_calendars + new_exchange;
        const calendars_for_exchange = await get_request(endpoint, token);
        if (calendars_for_exchange.is_success) {
            set_exchange_calendars(calendars_for_exchange.body);
        } else {
            set_response_msg({ text: "Failed to get calendars for exchange " + new_exchange, color: "red" });
        }
    }, [on_exchange_change, update_trading_calendar, getAccessTokenSilently])



    return (
        <div className="exchange-calendar-picker">
            <div className="exchange_calendar_node">
                <ExchangePicker exchanges={all_exchanges} on_change={(e) => update_active_exchange(e.target.value)} />
            </div>
            <div className="exchange_calendar_node">
                <p className="active-exchange-result-text" style={{ color: response_msg.color }}>{response_msg.text}</p>
            </div>
            {exchange !== "" && exchange_calendars.length !== 0 ? (
                <div className="exchange_calendar_node">
                    <CalendarPicker calendars={exchange_calendars} on_change={(e) => update_trading_calendar(e.target.value)} />
                </div>
            ) : (<> </>)}

        </div >
    )
}

export default ExchangeCalendarPicker;