const base_url = process.env.REACT_APP_BACKEND_ENDPOINT;

export class ResponseData {
    constructor(status_code, is_success, body, error) {
        this.status_code = status_code;
        this.is_success = is_success;
        this.body = body;
        this.error = error;
    }
}

export const get_request = async (endpoint, token) => {
    let url = base_url + endpoint;
    const request_params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
        },
    };
    return fetch_with_params(url, request_params);
};

export const post_request = async (endpoint, token, body) => {
    let url = base_url + endpoint;
    const request_params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
    };
    return fetch_with_params(url, request_params);
};

const fetch_with_params = async (url, request_params) => {
    try {
        const response = await fetch(url, request_params);
        if (response.status >= 200 && response.status <= 299) {
            const response_json = await response.json();
            console.log(response_json);
            return new ResponseData(response.status, true, response_json, null);
        } else {
            console.log(response.status, response.statusText);
            let error_msg = await response.text();
            console.error(error_msg);
            return new ResponseData(response.status, false, null, error_msg);
        }
    } catch (e) {
        const error_msg =
            "[API CALL ERROR] Unable to send request to " + url + " error=" + e;
        console.error(e);
        console.error(error_msg);
        return new ResponseData(-1, false, null, error_msg);
    }
};

export const endpoints = {
    // Base Scope
    get_accounts: `/base/accounts`,
    get_roots: `/base/roots`,
    get_exchanges: `/base/exchanges`,
    get_calendars: `/base/exchange_calendars/`,

    // Data Scope
    get_mkt_data_summary: `/data/mkt_data_summary`,
    get_consolidated_trade_data: `/data/consolidated_trade_data`,
    query_bar_data_state_machine: `/data/bar_data_state_machine`,
    query_account_data_state_machine: `/data/account_data_state_machine`,
    post_aum_override: `/data/override/aum_override`,
    post_bar_override: `/data/override/bar_override`,
    post_position_override: `/data/override/instrument_position_override`,
    post_weight_override: `/data/override/weight_override`,
    post_aggregate_request: `/data/aggregate_request`,
    post_holiday_override: `/data/override/holiday_override`,

    // Order Scope
    get_report_by_id: `/order/order_review/report/id/`,
    get_report_by_date: `/order/order_review/report/date/`,
    post_oms_orders: `/order/oms_orders`,
    post_approve_order: `/order/order_review/submit/order/approve`,
    post_reject_order: `/order/order_review/submit/order/reject`,
    post_approve_subreport: `/order/order_review/submit/subreport/approve`,
    post_reject_subreport: `/order/order_review/submit/subreport/reject`,
    post_approve_report: `/order/order_review/submit/report/approve`,
    post_reject_report: `/order/order_review/submit/report/reject`,
};

export const query_account_data_endpoint = (date, account) => {
    if (account === null) {
        return `${endpoints.query_account_data_state_machine}?date=${date}`;
    }
    return `${endpoints.query_account_data_state_machine}?date=${date}&account=${account}`;
};

export const query_bar_data_endpoint = (date, look_back) => {
    return `${endpoints.query_bar_data_state_machine}?date=${date}&look_back=${look_back}`;
};

export default ResponseData;
