import React from "react";
import "./TradeDataScreen.css";
import Header from "../Header";
import { useState, useEffect, useCallback } from "react";
import { endpoints, get_request } from "../requests";
import TradeDataSummary from "../components/trade_data/trade_data_summary";
import { useAuth0 } from "@auth0/auth0-react";

const TradeDataScreen = () => {
    const [trade_data, set_trade_data] = useState([]);
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

    const fetch_if_authenticated = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const response_data = await get_request(
                endpoints.get_consolidated_trade_data,
                token
            );
            if (response_data.is_success) {
                set_trade_data(response_data.body);
            }
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        fetch_if_authenticated();
    }, [fetch_if_authenticated, isLoading]);

    return (
        <div className="trade_data">
            <Header />
            <div className="trade_data_content">
                <h2 className="trade_data_title">Consolidated Trade Data</h2>
                <div className="all_trade_data_summaries">
                    {trade_data.length > 0 ? (
                        <>
                            {trade_data.map((trade_data, index) => (
                                <div
                                    key={index}
                                    className="single_trade_data_summary"
                                >
                                    <TradeDataSummary trade_data={trade_data} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TradeDataScreen;
