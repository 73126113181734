import "./Header.css";
import Nav from "./Nav";
import UserBadge from "./components/user_badge/user_badge";

const RAMHeader = () => {
    return (
        <div className="RAMheader">
            <div className="RAMheader_contents">
                <img
                    className="RAMheader_logo"
                    src="https://investresolve.com/inc/uploads/svg/resolve-logo-white.svg"
                    alt="RAM Logo"
                />
                <Nav />
                <UserBadge />
            </div>
        </div>
    );
};

export default RAMHeader;
