import RAMTable from "../RAMTable";

const PositionLimitSummary = ({ position_limit_summary }) => {
    const formatted_items = () => {
        for (let i in position_limit_summary) {
            position_limit_summary[i].limit_pct = parseFloat(
                (
                    (Math.abs(position_limit_summary[i].target_position) /
                        position_limit_summary[i].limit) *
                    100
                ).toFixed(2)
            );
        }
        return position_limit_summary;
    };
    return (
        <div className="position_limit_summary_content">
            <RAMTable
                items={formatted_items()}
                title={"Position Limit Report"}
                sorterValue={{ column: "limit_pct", state: "desc" }}
                scopedColumns={{
                    limit_pct: (item) => <td>{item.limit_pct}%</td>,
                }}
            />
        </div>
    );
};

export default PositionLimitSummary;
