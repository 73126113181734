import React from "react";
import "./aum_override.css";
import AccountPicker from "../account_picker";
import { useState, useCallback } from "react";
import { endpoints, post_request } from "../../requests";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "../date_picker";

const AUMOverride = ({ accounts }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [aum_override_account, set_aum_override_account] = useState("");
    const [aum_override_value, set_aum_override_value] = useState(0);
    const [aum_override_date, set_aum_override_date] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [is_btn_enabled, set_is_btn_enabled] = useState(true);

    const [display_message, set_display_message] = useState("");
    const [display_message_clr, set_display_message_clr] = useState("black");

    function aum_account_on_change(event) {
        set_display_message("");
        console.log("AUM Account selected: " + event.target.value);
        set_aum_override_account(event.target.value);
    }
    function aum_value_on_change(event) {
        set_display_message("");
        set_aum_override_value(event.target.value);
    }
    function aum_date_on_change(event) {
        set_display_message("");
        console.log("AUM date selected: " + event.target.value);
        set_aum_override_date(event.target.value);
    }

    const post_aum_override = useCallback(async () => {
        console.log("post_aum_override");
        set_is_btn_enabled(false);
        set_display_message("");
        if (aum_override_account === "" || aum_override_value === 0) {
            set_display_message("Please select an account and enter a value.");
            set_display_message_clr("red");
            set_is_btn_enabled(true);
            return;
        }
        const token = await getAccessTokenSilently();
        let body = {
            date: aum_override_date,
            account: aum_override_account,
            aum: aum_override_value,
        };
        const response_data = await post_request(
            endpoints.post_aum_override,
            token,
            body
        );
        if (response_data.is_success) {
            set_display_message("AUM override successful.");
            set_display_message_clr("green");
        } else {
            set_display_message(
                "Failed to override AUM. error=" + response_data.error
            );
            set_display_message_clr("red");
        }
        set_is_btn_enabled(true);
    }, [
        aum_override_account,
        aum_override_value,
        aum_override_date,
        getAccessTokenSilently,
    ]);

    return (
        <div className="aum_override">
            <h4 className="aum_override_title">AUM Override</h4>
            <div className="aum_override_user_input">
                <div className="aum_override_date">
                    <DatePicker
                        date={aum_override_date}
                        on_change={aum_date_on_change}
                    />
                </div>
                <div className="aum_account_picker">
                    <AccountPicker
                        accounts={accounts}
                        on_change={aum_account_on_change}
                        include_all_option={false}
                    />
                </div>

                <div className="aum_override_value">
                    <div className="aum_override_value_title">Enter AUM:</div>
                    <input
                        className="aum_override_value_input"
                        type="number"
                        value={aum_override_value}
                        onChange={aum_value_on_change}
                    />
                </div>
            </div>
            <div className="aum_override_submit">
                <input
                    type="button"
                    value="Override AUM"
                    className="aum_override_submit_btn ram_button"
                    onClick={post_aum_override}
                    disabled={!is_btn_enabled}
                />
                <p
                    className="aum_override_display_msg"
                    style={{ color: display_message_clr }}
                >
                    {display_message}
                </p>
            </div>
        </div>
    );
};

export default AUMOverride;
