import "./calendar_picker.css"

const CalendarPicker = ({ calendars, on_change }) => {
    return (
        <div className="calendar-picker">
            <div className="calendar-picker-title">Choose Calendar: </div>
            <select onChange={on_change} className="calendar-picker-select">
                <option key={-1} value="">
                    Choose Calendar
                </option>
                {calendars.map((calendar, index) => (
                    <option key={index} value={calendar}>
                        {calendar}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default CalendarPicker;