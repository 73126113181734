import React from "react";
import "./BarDataScreen.css";
import Header from "../Header";
import { useState, useCallback } from "react";
import { query_bar_data_endpoint, get_request } from "../requests";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "../components/date_picker";
import BarUpdateModal from "../components/bar_data/bar_update_modal";
import RAMTable from "../components/RAMTable";

var flatten = require("flat");

const BarDataScreen = () => {
    const [query_date, set_query_date] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [bar_data, set_bar_data] = useState(null);
    const [modal_visible, set_modal_visible] = useState(false);
    const [update_bar, set_update_bar] = useState(null);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [is_query_btn_enabled, set_is_query_btn_enabled] = useState(true);

    function format_response(response) {
        const flat_bar_data = response.bars.map(flatten);
        for (const bar of flat_bar_data) {
            bar.override = ""; // Add column for button
        }
        return flat_bar_data;
    }

    const fetch_if_authenticated = useCallback(async () => {
        set_bar_data(null);
        set_is_query_btn_enabled(false);
        set_response_msg({ text: "Request sent...", color: "black" });
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const endpoint = query_bar_data_endpoint(query_date, true);
            const response_data = await get_request(endpoint, token);
            if (response_data.is_success) {
                set_response_msg({ text: "", color: "white" });
                set_bar_data(format_response(response_data.body));
                set_is_query_btn_enabled(true);
            } else {
                set_response_msg({ text: response_data.error, color: "red" });
                set_is_query_btn_enabled(true);
            }
        }
    }, [getAccessTokenSilently, isAuthenticated, query_date]);

    function update_date(event) {
        console.log("Date Updated " + event.target.value);
        set_query_date(event.target.value);
    }

    function hide_modal() {
        set_update_bar(null);
        set_modal_visible(false);
    }
    function show_modal(item) {
        set_update_bar(item);
        set_modal_visible(true);
    }

    return (
        <div className="bar_data">
            <Header />
            <div className="bar_data_content">
                <h2 className="bar_data_title">Daily Bar Data</h2>
                <div className="bar_data_date_form">
                    <DatePicker date={query_date} on_change={update_date} />
                    <input
                        type="button"
                        value="Query Closed Bars"
                        className="bar_data_date_btn ram_button"
                        disabled={!is_query_btn_enabled}
                        onClick={fetch_if_authenticated}
                    />
                </div>
                <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                <div className="all_bar_data">
                    {bar_data ? (
                        <>
                            <h3 className="bar_data_subtitle">Closed Bars</h3>
                            <RAMTable
                                items={bar_data}
                                sorterValue={{
                                    column: "asset_name",
                                    state: "asc",
                                }}
                                single_page={true}
                                scopedColumns={{
                                    override: (item) => (
                                        <td>
                                            <button
                                                className="ord_review_approve_btn_sm"
                                                onClick={() => show_modal(item)}
                                            >
                                                Update
                                            </button>
                                        </td>
                                    ),
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <BarUpdateModal
                visible={modal_visible}
                call_on_close={hide_modal}
                bar={update_bar}
                allow_base_updates={false}
            />
        </div>
    );
};

export default BarDataScreen;
