import RAMTable from "../RAMTable";

const BrokerSummary = ({ broker_qty_summary }) => {
    return (
        <div className="broker_summary_content">
            <RAMTable
                title={"Broker Summaries"}
                items={broker_qty_summary}
                single_page={true}
                sorterValue={{ column: "broker", state: "asc" }}
            />
        </div>
    );
};

export default BrokerSummary;
