import { CButton, CCollapse, CCardBody } from "@coreui/react";
import { useState } from "react";
import { orders_columns } from "../../table_configs";
import OrderExpand from "../order_state/OrderExpand";
import RAMTable from "../RAMTable";

const OrdersTable = ({ orders }) => {
    const [details, setDetails] = useState([]);

    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };

    return (
        <div className="ordersTable">
            <RAMTable
                title=""
                columns={orders_columns}
                items={populated_order_summary(orders)}
                single_page={false}
                sorterValue={{ column: "state", state: "asc" }}
                scopedColumns={{
                    show_details: (item) => {
                        return (
                            <td className="py-2">
                                <CButton
                                    color="dark"
                                    variant="outline"
                                    shape="square"
                                    size="sm"
                                    onClick={() => {
                                        toggleDetails(item._id);
                                    }}
                                >
                                    {details.includes(item._id)
                                        ? "Hide"
                                        : "Show"}
                                </CButton>
                            </td>
                        );
                    },
                    details: (item) => {
                        return (
                            <CCollapse visible={details.includes(item._id)}>
                                <CCardBody>
                                    <OrderExpand order={item} />
                                </CCardBody>
                            </CCollapse>
                        );
                    },
                    effective_time: (item) =>
                        item.effective_time ? (
                            <td style={{ fontSize: "0.8em" }}>
                                {new Date(item.effective_time).toLocaleString(
                                    "en-CA"
                                )}
                            </td>
                        ) : (
                            <td></td>
                        ),
                }}
            />
        </div>
    );
};

export default OrdersTable;

function populated_order_summary(orders) {
    if (orders.order_summary) {
        for (let i = 0; i < orders.order_summary.length; i++) {
            orders.order_summary[i]["bidask"] =
                "(" +
                orders.order_summary[i]["bid"] +
                "/" +
                orders.order_summary[i]["ask"] +
                ")";

            orders.order_summary[i]["rem_qty"] =
                "(" +
                orders.order_summary[i]["exec_qty"] +
                "/" +
                orders.order_summary[i]["qty"] +
                ")";
        }
        return orders.order_summary;
    } else {
        return [];
    }
}
