const Dropdown = ({ submenus, dropdown }) => {
    return (
        <ul className={`navBar_dropdown_ul dropdown ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="navBar_dropdown_li">
                    <a className="navBar_link_title" href={submenu.url}>
                        {submenu.title}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;
