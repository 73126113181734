import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import DataScreen from "./screens/DataScreen";
import OrdersScreen from "./screens/OrdersScreen";
import OrderReviewScreen from "./screens/OrderReviewScreen";
import AccountDataScreen from "./screens/AccountDataScreen";
import TradeDataScreen from "./screens/TradeDataScreen";
import BarDataScreen from "./screens/BarDataScreen";
import DataOverrideScreen from "./screens/DataOverrideScreen";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/data/mkt_data_summary" component={DataScreen} />
          <Route exact path="/data/account_data" component={AccountDataScreen} />
          <Route exact path="/data/bar_data" component={BarDataScreen} />
          <Route exact path="/data/consolidated_trade_data" component={TradeDataScreen} />
          <Route exact path="/data/data_override" component={DataOverrideScreen} />
          <Route exact path="/order/oms_orders" component={OrdersScreen} />
          <Route exact path="/order/review" component={OrderReviewScreen} />
        </Switch>
      </Router>
    </div>
  );
}

export default withAuthenticationRequired(App);
