import { ReviewOption } from "./review_state";

const ReportSummary = ({ order_report, show_report_modal_fn }) => {
    return (
        <div className="order_review_report_summary">
            <hr />
            <div className="order_review_report_summary_title">
                Order Approval Report Summary
            </div>
            <div className="order_review_report_details">
                <div className="order_review_report_date">
                    Report date: {order_report.report_date}
                </div>
                <div className="order_review_report_transaction_date">
                    Transaction date: {order_report.transaction_date}
                </div>
            </div>
            <div className="order_review_report_buttons">
                <button
                    className="ord_review_approve_btn ram_button"
                    onClick={() =>
                        show_report_modal_fn(order_report, ReviewOption.APPROVE)
                    }
                >
                    Approve All & Release
                </button>
                <button
                    className="ord_review_reject_btn"
                    onClick={() =>
                        show_report_modal_fn(order_report, ReviewOption.REJECT)
                    }
                >
                    Reject All
                </button>
            </div>
        </div>
    );
};

export default ReportSummary;
