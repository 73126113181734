import "../order_state/OrderUpdateTable.css";

const OrderUpdateTable = ({ updates }) => {
    updates.sort(function (a, b) {
        return b.sequence_num - a.sequence_num;
    });
    return (
        <div className="order_update_table">
            <div className="order_update_table_header">Order Updates</div>
            <div className="order_update_table_content">
                <table>
                    <thead>
                        <tr>
                            <th>Sequence Num</th>
                            <th>Status</th>
                            <th>Remaining Qty</th>
                            <th>Executed Qty</th>
                            <th>Average Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {updates.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.sequence_num}</td>
                                    <td>{val.status}</td>
                                    <td>{val.remaining_qty}</td>
                                    <td>{val.exec_qty}</td>
                                    <td>{val.avg_px}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderUpdateTable;
