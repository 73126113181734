import { CModal, CModalHeader, CModalBody, CModalFooter } from "@coreui/react";
import "../SmartTable.css";
import "../SmartModal.css";
import BarDataOverride from "../data_override/bar_data_override";

const BarUpdateModal = ({
    visible,
    call_on_close,
    bar,
    allow_base_updates,
}) => {
    return (
        <>
            {visible && (
                <div>
                    <CModal visible={visible} onClose={() => call_on_close()}>
                        <CModalHeader onClose={() => call_on_close()}>
                            <p>Bar Price Override</p>
                        </CModalHeader>
                        <CModalBody>
                            <BarDataOverride
                                bar={bar}
                                allow_base_updates={allow_base_updates}
                            />
                        </CModalBody>
                        <CModalFooter>
                            <button
                                className="bar_update_close_btn  ram_button"
                                onClick={() => call_on_close()}
                            >
                                Close
                            </button>
                        </CModalFooter>
                    </CModal>
                </div>
            )}
        </>
    );
};

export default BarUpdateModal;
