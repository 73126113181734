import AccountSubReport from "./account_sub_report";
import AccountSummary from "./account_summary";
import BrokerSummary from "./broker_summary";
import ReportSummary from "./report_summary";
import RejectedSummary from "./rejected_summary";
import SanityCheckSummary from "./sanity_check_summary";
import PositionLimitSummary from "./position_limit_summary";
import MandateVolumeSummary from "./mandate_volume_summary";
import MarketVolumeSummary from "./market_volume_summary";
import { useState } from "react";
import ReviewModal from "./review_modal";
import ReviewState, { ReviewScope } from "./review_state";
import "./order_review.css";

const OrdersReview = ({ order_report, report_refresh_fn }) => {
    const [modal_visible, set_modal_visible] = useState(false);
    const [review_state, set_review_state] = useState(ReviewState.default());

    const show_review_modal = (review_State) => {
        set_modal_visible(true);
        set_review_state(review_State);
    };

    const hide_review_modal = () => {
        set_modal_visible(false);
        set_review_state(ReviewState.default());
    };

    const show_report_modal = (item, review_option) => {
        const review_state = new ReviewState(
            ReviewScope.REPORT,
            review_option,
            item,
            order_report.report_id,
            null,
            null
        );
        show_review_modal(review_state);
    };

    const acc_qty_summary_map = () => {
        let acc_qty_map = {};
        for (let i in order_report.account_qty_summary) {
            const acc = order_report.account_qty_summary[i];
            acc_qty_map[acc.account] = acc;
        }
        return acc_qty_map;
    };

    return (
        <div className="order_review_report_content">
            {order_report.sanity_checks.length > 0 ? (
                <div className="order_review_sanity_checks">
                    <SanityCheckSummary
                        sanity_checks={order_report.sanity_checks}
                    />
                </div>
            ) : (
                <></>
            )}
            <div className="order_review_report">
                {order_report.order_sub_reports
                    .sort((a, b) => (a.account > b.account ? 1 : -1))
                    .map((osr) => (
                        <AccountSubReport
                            order_sub_report={osr}
                            report_id={order_report.report_id}
                            key={osr.sub_report_id}
                            account_qty_summary={
                                acc_qty_summary_map()[osr.account]
                            }
                            show_review_modal_fn={show_review_modal}
                        />
                    ))}
                <div className="order_review_summaries">
                    <div className="order_review_account_summary">
                        <AccountSummary
                            account_qty_summary={
                                order_report.account_qty_summary
                            }
                        />
                    </div>
                    <div className="order_review_broker_summary">
                        <BrokerSummary
                            broker_qty_summary={order_report.broker_qty_summary}
                        />
                    </div>
                </div>
                <div className="order_review_pos_limit_summary">
                    <PositionLimitSummary
                        position_limit_summary={
                            order_report.position_limit_summary
                        }
                    />
                </div>
                <div className="order_review_volume_summaries">
                    <div className="order_review_mandate_volume_summary">
                        <MandateVolumeSummary
                            mandate_volume_summary={
                                order_report.mandate_volume_summary
                            }
                        />
                    </div>
                    <div className="order_review_market_volume_summary">
                        <MarketVolumeSummary
                            market_volume_summary={
                                order_report.market_volume_summary
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="order_review_report_summary">
                <ReportSummary
                    order_report={order_report}
                    show_report_modal_fn={show_report_modal}
                />
            </div>
            <hr />
            <div className="order_review_rejected_summary">
                <RejectedSummary
                    rejected_summary={order_report.rejected_summary}
                />
            </div>
            <div className="order_review_report_modal">
                {modal_visible ? (
                    <ReviewModal
                        visible={modal_visible}
                        call_on_close={hide_review_modal}
                        review_state={review_state}
                        report_refresh_fn={report_refresh_fn}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default OrdersReview;
