import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_request } from "../../requests";
import "./holiday_override.css";
import ExchangePicker from "../exchange_picker";
import RootPicker from "../root_picker";
import ExchangeCalendarPicker from "../exchange_calendar_picker";

const ConfirmText = {
    OverrideHoliday: "Override Holiday",
    DoubleCheck: "Confirm Double Check",
    Success: "Success!",
}



const HolidayOverride = ({ all_exchanges, all_roots }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [confirm_btn_txt, set_confirm_btn_txt] = useState(
        ConfirmText.OverrideHoliday
    );
    const [date, set_date] = useState(new Date().toISOString().split("T")[0]);
    const [override_level, set_override_level] = useState("-1");
    const [root, set_root] = useState("");
    const [exchange, set_exchange] = useState("");
    const [calendar_exchange, set_calendar_exchange] = useState("");
    const [calendar, set_calendar] = useState({});
    const [override_type, set_override_typing] = useState("-1");

    const clear_dropdown_selections = useCallback(async () => {
        set_root("");
        set_exchange("");
        set_calendar_exchange("");
        set_calendar("");
        set_override_typing("-1");
    }, [])

    const update_override_level = useCallback(async (new_override_level) => {
        clear_dropdown_selections();
        set_override_level(new_override_level);

    }, [clear_dropdown_selections])


    const submit_holiday_override = useCallback(async (holiday_override) => {
        const token = await getAccessTokenSilently();
        const response = await post_request(endpoints.post_holiday_override, token, holiday_override);
        if (response.is_success) {
            set_response_msg({ text: "Success!", color: "green" });
            set_confirm_btn_txt(ConfirmText.Success);
        } else {
            set_response_msg({ text: "Failed to override holiday", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
        }
    }, [getAccessTokenSilently])

    const process_exchange_holiday_override = useCallback(async () => {
        if (exchange === "" || override_type === "") {
            set_response_msg({ text: "Please select an exchange and override type", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
            return;
        }
        set_response_msg({ text: "Sending override...", color: "black" });
        const override_level = { "Exchange": exchange };
        const body = { date, override_level, override_type };
        submit_holiday_override(body);
    }, [exchange, override_type, date, submit_holiday_override])

    const process_instrument_root_override = useCallback(async () => {
        if (root === "" || override_type === "") {
            set_response_msg({ text: "Please select an instrument root and override type", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
            return;
        }
        set_response_msg({ text: "Sending override...", color: "black" });
        const override_level = { "Root": root };
        const body = { date, override_level, override_type };
        submit_holiday_override(body);
    }, [root, override_type, date, submit_holiday_override])

    const process_exchange_calendar_override = useCallback(async () => {
        if (calendar_exchange === "" || calendar === "" || override_type === "") {
            set_response_msg({ text: "Please select an exchange, calendar, and override type", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
            return;
        }
        set_response_msg({ text: "Sending override...", color: "black" });
        const override_level = { "ExchangeCalendar": { "exchange": calendar_exchange, "calendar": calendar } };
        const body = { date, override_level, override_type };
        submit_holiday_override(body);

    }, [calendar_exchange, calendar, override_type, date, submit_holiday_override])

    const on_holiday_confirmed = useCallback(async () => {
        if (override_level === "-1") {
            set_response_msg({ text: "Please select an override level", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
            return;
        }
        if (date === "") {
            set_response_msg({ text: "Please select a date", color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideHoliday);
            return;
        }
        if (override_level === "Exchange") {
            process_exchange_holiday_override();
        } else if (override_level === "Root") {
            process_instrument_root_override();
        } else if (override_level === "ExchangeCalendar") {
            process_exchange_calendar_override();
        }
    }, [date,
        override_level,
        process_exchange_calendar_override,
        process_exchange_holiday_override,
        process_instrument_root_override]);

    return (
        <div className="holiday_override">
            <div className="holiday_override_content">
                <div className="holiday_override_node">
                    <label>Date: </label>
                    <input
                        className="holiday_override_date"
                        type="date"
                        value={date}
                        onChange={(e) => set_date(e.target.value)}
                    />
                </div>
                <div className="holiday_override_node">
                    <div className="holiday_override_level">
                        <OverrideLevelPicker on_change={(event) => update_override_level(event.target.value)} />
                    </div>
                </div>
                {override_level === "Exchange" ? (
                    <div className="holiday_override_node">
                        <div className="holiday_override_exchange">
                            <ExchangePicker exchanges={all_exchanges}
                                on_change={(e) => set_exchange(e.target.value)}
                            />
                        </div>
                    </div>
                ) : (<></>)}
                {override_level === "ExchangeCalendar" ? (
                    <div className="holiday_override_node">
                        <div className="holiday_override_exchange_calendar">
                            <ExchangeCalendarPicker all_exchanges={all_exchanges}
                                on_exchange_change={(e) => set_calendar_exchange(e)}
                                on_calendar_change={(e) => set_calendar(e)}
                            />
                        </div>
                    </div>
                ) : (<></>)}
                {override_level === "Root" ? (
                    <div className="holiday_override_node">
                        <div className="holiday_override_instrument">
                            <RootPicker roots={all_roots}
                                on_change={(event) => set_root(event.target.value)}
                                include_all_option={false} />
                        </div>
                    </div>
                ) : (<></>)}
                <div className="holiday_override_node">
                    <div className="holiday_override_type">
                        <OverrideTypePicker on_change={(event) => set_override_typing(event.target.value)} />
                    </div>
                </div>
                <div className="holiday_override_footer">
                    <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                    <button
                        className="bar_update_submit_btn ram_button"
                        onClick={on_holiday_confirmed}
                    >
                        {confirm_btn_txt}
                    </button>
                </div>
            </div>
        </div >
    );
};

const OverrideLevelPicker = ({ on_change }) => {
    return (
        <div className="override_level_picker">
            <div className="override_level_picker_title">Override Level: </div>
            <select onChange={on_change} className="override_level_picker_select">
                <option key={-1} value="-1">
                    Choose Override Level
                </option>
                <option key={0} value="Exchange">
                    Exchange
                </option>
                <option key={1} value="Root">
                    Instrument Root
                </option>
                <option key={2} value="ExchangeCalendar">
                    Exchange Calendar
                </option>
            </select>
        </div>
    );
}

const OverrideTypePicker = ({ on_change }) => {
    return (
        <div className="override_type_picker">
            <div className="override_type_picker_title">Override Type: </div>
            <select onChange={on_change} className="override_type_picker_select">
                <option key={-1} value="-1">
                    Choose Override Type
                </option>
                <option key={0} value="AddHoliday">
                    Add Holiday
                </option>
                <option key={1} value="RemoveHoliday">
                    Remove Holiday
                </option>
            </select>
        </div>
    );
}

export default HolidayOverride;