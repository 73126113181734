import { endpoints } from "../../requests";

export const ReviewOption = {
    APPROVE: "APPROVE",
    REJECT: "REJECT",
};

export const ReviewScope = {
    ORDER: "ORDER",
    SUB_REPORT: "SUB_REPORT",
    REPORT: "REPORT",
};

class ReviewState {
    constructor(
        review_scope,
        review_option,
        item,
        report_id,
        sub_report_id,
        order_id
    ) {
        this.review_scope = review_scope;
        this.review_option = review_option;
        this.item = item;
        this.order_id = order_id;
        this.report_id = report_id;
        this.sub_report_id = sub_report_id;
    }

    static default() {
        return new ReviewState(null, null, {}, null, null, null);
    }

    endpoint() {
        if (this.review_scope === ReviewScope.ORDER) {
            if (this.review_option === ReviewOption.APPROVE) {
                return endpoints.post_approve_order;
            } else if (this.review_option === ReviewOption.REJECT) {
                return endpoints.post_reject_order;
            }
        } else if (this.review_scope === ReviewScope.SUB_REPORT) {
            if (this.review_option === ReviewOption.APPROVE) {
                return endpoints.post_approve_subreport;
            } else if (this.review_option === ReviewOption.REJECT) {
                return endpoints.post_reject_subreport;
            }
        } else if (this.review_scope === ReviewScope.REPORT) {
            if (this.review_option === ReviewOption.APPROVE) {
                return endpoints.post_approve_report;
            } else if (this.review_option === ReviewOption.REJECT) {
                return endpoints.post_reject_report;
            }
        }
    }

    body(by, comment) {
        let body = { report_id: this.report_id, by: by };
        if (comment) {
            body["comment"] = comment;
        }
        if (this.review_scope === ReviewScope.ORDER) {
            body["order_id"] = this.order_id;
            body["sub_report_id"] = this.sub_report_id;
            return body;
        } else if (this.review_scope === ReviewScope.SUB_REPORT) {
            body["sub_report_id"] = this.sub_report_id;
            return body;
        } else if (this.review_scope === ReviewScope.REPORT) {
            return body;
        }
    }
}

export default ReviewState;
