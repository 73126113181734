import "./account_picker.css";

const AccountPicker = ({
    accounts,
    on_change,
    include_all_option,
    default_account,
    disabled,
}) => {
    return (
        <div className="account_picker">
            <div className="account_picker_title">Choose Account: </div>
            <select
                onChange={on_change}
                disabled={disabled}
                className="account_picker_select"
                value={default_account}
            >
                <option key={-1} value="">
                    Choose Account
                </option>
                {accounts.map((account, index) => (
                    <option key={index} value={account}>
                        {account}
                    </option>
                ))}
                {include_all_option ? (
                    <option key={-2} value="all">
                        All Accounts
                    </option>
                ) : (
                    <></>
                )}
            </select>
        </div>
    );
};

export default AccountPicker;
