import { mkt_data_columns } from "../../table_configs";
import RAMTable from "../RAMTable";

const MktDataTable = ({ mkt_data }) => {
    return (
        <div className="mktdata_Table">
            <RAMTable
                title={"Market Data"}
                columns={mkt_data_columns}
                items={populated_json(mkt_data)}
                sorterValue={{ column: "instrument", state: "asc" }}
                scopedColumns={{
                    create_time: (item) => (
                        <td style={{ fontSize: "0.8em" }}>
                            {new Date(item.create_time).toLocaleString("en-CA")}
                        </td>
                    ),
                    quote_datetime: (item) => (
                        <td style={{ fontSize: "0.8em" }}>
                            {item.quote_datetime &&
                                new Date(item.quote_datetime).toLocaleString(
                                    "en-CA"
                                )}
                        </td>
                    ),
                }}
            />
        </div>
    );
};

export default MktDataTable;

function populated_json(json) {
    if (json) {
        for (let i = 0; i < json.length; i++) {
            // Bid Ask
            const bid = json[i]["bid"];
            const ask = json[i]["ask"];
            json[i]["bidask"] = bid + "/" + ask;
            // Close Bid Ask
            const cbid = json[i]["close_bid"];
            const cask = json[i]["close_ask"];
            json[i]["close_bidask"] = cbid + "/" + cask;
        }
        return json;
    } else {
        return [];
    }
}
