import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./login";
import LogoutButton from "./logout";
import "./user_badge.css";

const UserBadge = () => {
    const { user, isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        let profile_name = user.email.split("@")[0];
        return (
            <div className="RAM_user_badge">
                <div className="RAM_logout_btn">{LogoutButton()}</div>
                <div className="RAM_user_name">Profile: {profile_name}</div>
            </div>
        );
    } else {
        return LoginButton();
    }
};

export default UserBadge;
