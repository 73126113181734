import React from "react";
import "./AccountDataScreen.css";
import Header from "../Header";
import { useState, useCallback } from "react";
import { query_account_data_endpoint, get_request } from "../requests";
import AccountDataSummary from "../components/account_data/account_data_summary";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "../components/date_picker";

const AccountDataScreen = () => {
    const [query_date, set_query_date] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [account_data, set_account_data] = useState({ account_data: [] });
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [is_query_btn_enabled, set_is_query_btn_enabled] = useState(true);

    function format_response(response) {
        for (const account of response.account_data) {
            if (account.positions) {
                for (const position of account.positions) {
                    position.override = ""; // Add column for button
                }
            }
            if (account.weights) {
                for (const weight of account.weights) {
                    weight.override = ""; // Add column for button
                }
            }
        }
        return response;
    }

    const fetch_if_authenticated = useCallback(async () => {
        set_account_data({ account_data: [] });
        set_is_query_btn_enabled(false);
        set_response_msg({ text: "Request sent...", color: "black" });
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            const endpoint = query_account_data_endpoint(query_date, null);
            const response_data = await get_request(endpoint, token);
            if (response_data.is_success) {
                set_response_msg({ text: "", color: "white" });
                set_account_data(format_response(response_data.body));
                set_is_query_btn_enabled(true);
            } else {
                set_response_msg({ text: response_data.error, color: "red" });
                set_is_query_btn_enabled(true);
            }
        }
    }, [getAccessTokenSilently, isAuthenticated, query_date]);

    function update_date(event) {
        console.log("Date Updated " + event.target.value);
        set_query_date(event.target.value);
    }

    return (
        <div className="account_data">
            <Header />
            <div className="account_data_content">
                <h2 className="account_data_title">Account Data</h2>
                <div className="account_data_date_form">
                    <DatePicker date={query_date} on_change={update_date} />
                    <input
                        type="button"
                        value="Query Account Data"
                        className="account_data_date_btn ram_button"
                        disabled={!is_query_btn_enabled}
                        onClick={fetch_if_authenticated}
                    />
                </div>
                <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                <div className="all_account_data_summaries">
                    {account_data.account_data ? (
                        <>
                            {account_data.account_data.map(
                                (account_data, index) => (
                                    <div
                                        key={index}
                                        className="single_account_data_summary"
                                    >
                                        <AccountDataSummary
                                            account_data={account_data}
                                        />
                                    </div>
                                )
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountDataScreen;
