import React from "react";
import "./trade_data_override.css";
import AccountPicker from "../account_picker";
import RootMultiPicker from "../root_multipicker";
import { useState, useCallback } from "react";
import { endpoints, post_request } from "../../requests";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "../date_picker";

const TradeDataGenerate = ({ accounts, roots }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [trade_data_override_accounts, set_trade_data_override_accounts] =
        useState("");
    const [trade_data_override_roots, set_trade_data_override_roots] =
        useState([]);
    const [trade_data_override_date, set_trade_data_override_date] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [is_btn_enabled, set_is_btn_enabled] = useState(true);
    const [display_message, set_display_message] = useState("");
    const [display_message_clr, set_display_message_clr] = useState("black");

    function trade_data_account_on_change(event) {
        set_display_message("");
        console.log("Account selected: " + event.target.value);
        if (event.target.value === "all") {
            set_trade_data_override_accounts(null);
        } else if (event.target.value === "") {
            set_trade_data_override_accounts(event.target.value);
        } else {
            set_trade_data_override_accounts([event.target.value]);
        }
    }
    function trade_data_root_on_change(event) {
        set_display_message("");
        let values = event.map((option) => option.value);
        let selected_roots = []
        for (let value of values) {
            if (value === "all") {
                selected_roots = ["all"];
                break;
            }
            selected_roots.push(value);
        }

        console.log("Roots selected: " + selected_roots)
        set_trade_data_override_roots(selected_roots);
    }
    function trade_data_date_on_change(event) {
        set_display_message("");
        console.log("Date selected: " + event.target.value);
        set_trade_data_override_date(event.target.value);
    }

    const post_trade_data_override = useCallback(async (date, accounts, roots) => {
        for (let root of roots) {
            if (root === "all") {
                roots = null;
                break;
            }
        }
        const token = await getAccessTokenSilently();
        let body = {
            date,
            accounts,
            roots,
        };
        const response_data = await post_request(
            endpoints.post_aggregate_request,
            token,
            body
        );
        return response_data;
    }, [getAccessTokenSilently]);

    const post_trade_data_overrides = useCallback(async () => {
        console.log("post_trade_data_override");
        set_is_btn_enabled(false);
        set_display_message("");
        if (
            trade_data_override_accounts === "" ||
            trade_data_override_roots.length === 0
        ) {
            set_display_message("Please select an account, and root option.");
            set_display_message_clr("red");
            set_is_btn_enabled(true);
            return;
        }
        let response_data = await post_trade_data_override(trade_data_override_date, trade_data_override_accounts, trade_data_override_roots);
        if (response_data.is_success) {
            set_display_message("Data Aggregation successful.");
            set_trade_data_override_accounts("");
            set_trade_data_override_roots([]);
            set_display_message_clr("green");
        } else {
            set_display_message("Error: " + response_data.error);
            set_display_message_clr("red");
        }

        set_is_btn_enabled(true);
    }, [
        trade_data_override_accounts,
        trade_data_override_date,
        trade_data_override_roots,
        post_trade_data_override
    ]);

    return (
        <div className="trade_data_override">
            <h4 className="trade_data_override_title">Order Generation</h4>
            <div className="trade_data_override_user_input">
                <div className="trade_data_override_date">
                    <DatePicker
                        date={trade_data_override_date}
                        on_change={trade_data_date_on_change}
                    />
                </div>
                <div className="trade_data_account_picker">
                    <AccountPicker
                        accounts={accounts}
                        on_change={trade_data_account_on_change}
                        include_all_option={true}
                    />
                </div>
                <div className="trade_data_root_picker">
                    <RootMultiPicker
                        roots={roots}
                        on_change={trade_data_root_on_change}
                        include_all_option={true}
                    />
                </div>
            </div>
            <div className="trade_data_override_submit">
                <input
                    type="button"
                    value="Generate Orders"
                    className="trade_data_override_submit_btn ram_button"
                    onClick={post_trade_data_overrides}
                    disabled={!is_btn_enabled}
                />
                <p
                    className="trade_data_override_display_msg"
                    style={{ color: display_message_clr }}
                >
                    {display_message}
                </p>
            </div>
        </div>
    );
};

export default TradeDataGenerate;
