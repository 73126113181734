import RAMTable from "../RAMTable";

const RejectedSummary = ({ rejected_summary }) => {
    return (
        <div className="rejected_summary_content">
            <RAMTable
                title={"Rejected Orders Report"}
                items={rejected_summary}
                sorterValue={{ column: "account", state: "asc" }}
                scopedColumns={{
                    order_id: (item) => <td>{item.order_id.slice(0, 8)}</td>,
                }}
            />
        </div>
    );
};

export default RejectedSummary;
