import { CModal, CModalHeader, CModalBody, CModalFooter } from "@coreui/react";
import "../SmartTable.css";
import "../SmartModal.css";
import "./review_modal.css";
import { ReviewOption } from "./review_state";
import ReviewModalContent from "./review_modal_content";
import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { post_request } from "../../requests";

const ReviewModal = ({
    visible,
    call_on_close,
    review_state,
    report_refresh_fn,
}) => {
    const { getAccessTokenSilently, user, isLoading } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [comment, set_comment] = useState("");
    const [is_confirm_btn_enabled, set_is_confirm_btn_enabled] = useState(true);

    const on_review_confirmed = useCallback(async () => {
        set_is_confirm_btn_enabled(false);
        const token = await getAccessTokenSilently();
        const endpoint = review_state.endpoint();
        const body = review_state.body(user.email, comment);
        set_response_msg({ text: "Sending review...", color: "black" });
        const response_data = await post_request(endpoint, token, body);
        if (response_data.is_success) {
            set_response_msg({ text: "Success!", color: "green" });
            report_refresh_fn();
        } else {
            set_response_msg({ text: response_data.error, color: "red" });
            set_is_confirm_btn_enabled(true);
        }
    }, [
        getAccessTokenSilently,
        review_state,
        user.email,
        report_refresh_fn,
        comment,
    ]);

    const review_button = () => {
        if (review_state.review_option === ReviewOption.APPROVE) {
            return (
                <button
                    className="ord_review_btn ram_button"
                    disabled={!is_confirm_btn_enabled}
                    onClick={on_review_confirmed}
                >
                    Approve
                </button>
            );
        } else if (review_state.review_option === ReviewOption.REJECT) {
            return (
                <button
                    className="ord_review_reject_btn"
                    disabled={!is_confirm_btn_enabled}
                    onClick={on_review_confirmed}
                >
                    Reject
                </button>
            );
        } else {
            return <></>;
        }
    };

    function call_on_close_wrapper() {
        set_response_msg("");
        set_comment("");
        set_is_confirm_btn_enabled(true);
        call_on_close();
    }

    function on_comment_change(event) {
        set_comment(event.target.value);
    }

    return (
        <>
            {visible && !isLoading && (
                <div>
                    <CModal
                        visible={visible}
                        onClose={() => call_on_close_wrapper()}
                    >
                        <CModalHeader onClose={() => call_on_close_wrapper()}>
                            <p>
                                Are you sure you want to{" "}
                                <b>{review_state.review_option}</b>{" "}
                                {review_state.review_scope.toLowerCase()}?
                            </p>
                        </CModalHeader>
                        <CModalBody>
                            <div className="review_modal_body">
                                <ReviewModalContent
                                    review_state={review_state}
                                />
                                <div className="review_modal_comment">
                                    <h4 className="review_modal_comment_header">
                                        Comment:
                                    </h4>
                                    <textarea
                                        className="review_modal_textarea"
                                        value={comment}
                                        onChange={on_comment_change}
                                        maxLength={300}
                                        placeholder="Type comment here..."
                                        rows={3}
                                        spellCheck={true}
                                    />
                                </div>
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <p style={{ color: response_msg.color }}>
                                {response_msg.text}
                            </p>
                            <button
                                className="ord_review_btn ram_button"
                                onClick={() => call_on_close_wrapper()}
                            >
                                Close
                            </button>
                            {review_button(review_state)}
                        </CModalFooter>
                    </CModal>
                </div>
            )}
        </>
    );
};

export default ReviewModal;
