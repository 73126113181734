import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_request } from "../../requests";
import "./position_data_override.css";
import AccountPicker from "../account_picker";

const BlockType = {
    BlockIfShould: "BlockIfShould",
    Allow: "Allow",
};

const ConfirmText = {
    OverrideQuantity: "Override Position",
    DoubleCheck: "Confirm Double Check",
    Success: "Success!",
};

const PositionDataOverride = ({
    position,
    allow_base_updates,
    all_accounts,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [response_msg, set_response_msg] = useState({
        text: "",
        color: "white",
    });
    const [is_confirm_btn_enabled, set_is_confirm_btn_enabled] = useState(true);
    const [confirm_btn_txt, set_confirm_btn_txt] = useState(
        ConfirmText.OverrideQuantity
    );

    const [account, set_account] = useState(position.account || "");
    const [instrument, set_instrument] = useState(position.instrument || "");
    const [quantity, set_quantity] = useState(position.quantity || "");
    const [init_quantity] = useState(
        !allow_base_updates ? parseFloat(quantity) : ""
    );
    const [block_type, set_block_type] = useState(
        !allow_base_updates ? BlockType.BlockIfShould : BlockType.Allow
    );
    const [date, set_date] = useState(
        position.date || new Date().toISOString().split("T")[0]
    );

    const on_position_confirmed = useCallback(async () => {
        set_is_confirm_btn_enabled(false);
        set_response_msg({ text: "Sending update...", color: "black" });
        if (account === "" || instrument === "" || quantity === "") {
            set_response_msg({
                text: "Please fill in all fields.",
                color: "red",
            });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideQuantity);
            return;
        }

        const new_quantity = parseFloat(quantity);
        if (new_quantity === init_quantity) {
            set_response_msg({ text: "No change detected.", color: "red" });
            set_is_confirm_btn_enabled(true);
            set_confirm_btn_txt(ConfirmText.OverrideQuantity);
            return;
        }

        if (block_type === BlockType.BlockIfShould) {
            const percent_change = Math.abs(
                ((new_quantity - init_quantity) / init_quantity) * 100
            );
            if (percent_change > 10) {
                set_response_msg({
                    text: "Please double check quantity...",
                    color: "black",
                });
                set_block_type(BlockType.Allow);
                set_confirm_btn_txt(ConfirmText.DoubleCheck);
                set_is_confirm_btn_enabled(true);
                return;
            }
        }

        set_response_msg({ text: "Sending update...", color: "black" });
        set_block_type(BlockType.BlockIfShould);
        const body = {
            date,
            account,
            instrument: instrument.replace(" ", ""),
            quantity: parseInt(quantity),
        };
        const token = await getAccessTokenSilently();
        const response_data = await post_request(
            endpoints.post_position_override,
            token,
            body
        );
        if (response_data.is_success) {
            set_response_msg({ text: "Success!", color: "green" });
            set_is_confirm_btn_enabled(false);
            set_confirm_btn_txt(ConfirmText.Success);
        } else {
            set_response_msg({ text: response_data.error, color: "red" });
            set_confirm_btn_txt(ConfirmText.OverrideQuantity);
            set_is_confirm_btn_enabled(true);
        }
    }, [
        account,
        instrument,
        quantity,
        date,
        getAccessTokenSilently,
        init_quantity,
        block_type,
    ]);

    return (
        <div className="position_data_override">
            <div className="position_data_override_values">
                <div className="position_data_override_node">
                    <label>Date: </label>
                    <input
                        className="position_data_override_date"
                        type="date"
                        disabled={!allow_base_updates}
                        value={date}
                        onChange={(event) => set_date(event.target.value)}
                    />
                </div>
                <div className="position_data_override_node">
                    <div className="position_account_picker">
                        <AccountPicker
                            accounts={all_accounts}
                            on_change={(event) =>
                                set_account(event.target.value)
                            }
                            include_all_option={false}
                            default_account={account}
                            disabled={!allow_base_updates}
                        />
                    </div>
                </div>
                <div className="position_data_override_node">
                    <label>Instrument: </label>
                    <input
                        className="position_data_override_instrument"
                        type="other"
                        disabled={!allow_base_updates}
                        value={instrument}
                        onChange={(event) => set_instrument(event.target.value)}
                    />
                </div>
                <div className="position_data_override_node">
                    <label>Quantity: </label>
                    <input
                        className="position_data_override_qty"
                        type="number"
                        value={quantity}
                        onChange={(event) => set_quantity(event.target.value)}
                    />
                </div>
            </div>
            <div className="position_data_override_footer">
                <p style={{ color: response_msg.color }}>{response_msg.text}</p>
                <button
                    className="bar_update_submit_btn ram_button"
                    disabled={!is_confirm_btn_enabled}
                    onClick={on_position_confirmed}
                >
                    {confirm_btn_txt}
                </button>
            </div>
        </div>
    );
};

export default PositionDataOverride;
