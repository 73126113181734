import RAMTable from "../RAMTable";

const MarketVolumeSummary = ({ market_volume_summary }) => {
    const formatted_items = () => {
        for (let i in market_volume_summary) {
            market_volume_summary[i].volume_avg = parseFloat(
                market_volume_summary[i].volume_avg
            );
            market_volume_summary[i].volume_pct = parseFloat(
                (
                    (Math.abs(market_volume_summary[i].target_position) /
                        market_volume_summary[i].volume_avg) *
                    100
                ).toFixed(2)
            );
        }
        return market_volume_summary;
    };

    return (
        <div className="market_volume_summary_content">
            <RAMTable
                title={"Market Volume Report"}
                items={formatted_items()}
                sorterValue={{ column: "volume_pct", state: "desc" }}
                scopedColumns={{
                    volume_pct: (item) => <td>{item.volume_pct}%</td>,
                }}
            />
        </div>
    );
};

export default MarketVolumeSummary;
